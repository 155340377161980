<template>
    <div class="content">
        <div class="top">
            <!-- 轮播图 -->
            <div class="swipes">
                <div class="wrapper">
                    <el-carousel indicator-position="outside" :interval="4000"  type="card" height="20vh">
                        <el-carousel-item v-for="item in swipes" :key="item.id">
                            <img :src="item.url" @click="onBack(item.href)" alt="" style="width: 100%; height: 100%;"/>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Carousel",

        data() {
            return {
                swipes: [
                    {'id': 0, 'url': require("@/assets/xyhd/banner_dida.png"),'href':'https://xindai.duxiaoman.com/cloan/operation/activity?activityName=channelBrand&CH=jmall&fr=KdICRRr40'},
                    {'id': 1, 'url': require("@/assets/xyhd/banner_huaxiaozhu.jpg"),'href':'https://v.didi.cn/dpMzRPk?source_id=zy01'},
                    {'id': 2, 'url': require("@/assets/xyhd/banner_didi.png"),'href':'https://v.didi.cn/LMnE8oZ?source_id=zy02'},
                ],
                categories: [],
            };

        },
        methods:{
            onBack(src){
                console.log(src);
                window.location.href=src;
            },
        }
    }
</script>

<style lang="scss">
    .content {
        height: 400vm;
        .swipes {
            .wrapper{
                .el-carousel {
                    width:  100%;
                }
                .el-carousel__item img {
                    color: #475669;
                    font-size: 18px;
                    opacity: 0.75;
                    line-height: 300px;
                    margin: 0;
                }
                .el-carousel__item:nth-child(2n) {
                    background-color: #99a9bf;
                }
                .el-carousel__item:nth-child(2n+1) {
                    background-color: #d3dce6;
                }
            }
        }
    }
</style>