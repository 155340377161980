<template>
    <div class="header_frame">
        <span class="head_span">小潼福利平台</span>
    </div>
</template>

<script>
    export default {
        name: "Header"
    }
</script>

<style lang="scss">
    .header_frame{
        width: 100%;
        height: 9.5vh;
        border:1px solid #e9e9e9;
        background-color: #EA5455;
        color: #FFD460;
        display: flex;
        align-items: center;
        .head_span {
            margin: auto;
            font-size: 3.1rem;
        }
    }
</style>