<template>
	<van-action-sheet
		v-model="visible"
		:title="item.title"
	>
		<iframe class="iframe" :src="`@/assets/xyhd/${ item.path }`" frameborder="0"></iframe>
	</van-action-sheet>
</template>

<script>
	export default {
		data: () => ({
			visible: false,
			list: [
				{ id: '5', title: '《隐私政策》', path: 'I_yinsizhengce.html' },

			],
			item: {}
		}),
		methods: {
			handle (id) {
				this.visible = false
				this.$nextTick(() => {
					const item = this.list.find(val => (id + '') === val.id)
					this.item = item || this.list[0]
					this.visible = true
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.iframe {
		width: 100%;
		height: 70vh;
	}
</style>
