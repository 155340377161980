
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path:"/",
    redirect:"/login/index"
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      scrollToTop: true
    },
    component: () => import('../views/Home.vue')
  },
  //  登录
  {
    path: '/login/index',
    name: 'Index',
    meta: {
      scrollToTop: true
    },
    component: () => import('../login/Index.vue')
  },
  //  注册
  {
    path: '/register/index',
    name: 'Index',
    meta: {
      scrollToTop: true
    },
    component: () => import('../register/Index')
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 防止路由重复点击报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
